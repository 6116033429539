<template>
  <div class="content-wrapper user-page">
    <h1 class="mb-5">StatusCheck</h1>

    <b-card class="mb-4">
      <b-row class="my-1 justify-content-start">

        <b-col cols="auto">
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Cerca cliente...">
            </b-form-input>
          </b-input-group>
        </b-col>


      </b-row>
    </b-card>



    <b-card class="mb-4">
      <b-table hover :items="items_client" :fields="fields_client" sort-icon-left :current-page="currentPage"
        :per-page="perPage" :filter="filter" localSortBy="attivo" localSortDesc="true">

      </b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
        class="mt-4 mb-1"></b-pagination>
    </b-card>






  </div>
</template>

<script>
// import { AUTH_REQUEST } from "../store/actions/auth";
// import store from "../store";
// import { mapGetters } from "vuex";
import axios from "axios";
import AxiosService from "../axiosServices/AxiosService";

export default {
  name: "LicenceManager",
  service: null,

  created() {
    this.service = new AxiosService("LicenceManager");
  },
  mounted() {
    this.service.readCustomEndpoint("Licence/GetClientsList/").then((res) => {
      this.items_client = res.filter(x => x.attivo)
    });
  },
  data() {
    return {


      testUser: {
        username: '',
        password: ''
      },

      filter: "",
      currentPage: 1,
      perPage: 10,
      items_client: [],
      fields_client: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "test", label: "Test" },
      ],
    };
  },
  methods: {

    callLogin(endpoint) {

      axios.get(endpoint, { params: this.testUser })
        .then(res => {
          console.log("🚀 ~ file: StatusCheck.vue ~ line 86 ~ callLogin ~ res", res)


        })
        .catch(err => {
          console.log("🚀 ~ file: StatusCheck.vue ~ line 91 ~ callLogin ~ err", err)

        });
    }
  },
  computed: {
    totalRows() {
      return this.items_client ? this.items_client.length : 0;
    },


  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
};
</script>